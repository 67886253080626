<template>
    <v-app>
        <v-app-bar justify="center" color="matchpal">
            <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
            <v-toolbar-title>Histórico de Ações</v-toolbar-title>
        </v-app-bar>

        <v-card>
            <v-card-title>
                <v-select
                v-model="periodo"
                :items="periodos"
                label="Selecione o período"
                @input="fetchEvents"
                />

                <v-icon
                v-if="events.length > 0"
                class="ml-4"
                color="primary"
                @click="download">fa fa-download</v-icon>
            </v-card-title>

            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="search"
                label="Filtrar"
                single-line
                hide-details
                />

                <v-data-table
                    dense
                    :headers="headers"
                    :items="events"
                    :footer-props="{ 'items-per-page-options': [20,50,100,-1] }"
                    :items-per-page="20"
                    :loading="loading"
                    :search="search"
                    item-key="id"
                    class="elevation-1"
                    />
            </v-card-text>
        </v-card>

    </v-app>
</template>

<script>
import moment from 'moment'
import Utils from "@components/Utils/Utils.vue"
import * as Xlsx from 'xlsx'


moment.locale('pt')

export default {
    name: "Historico",
    components: {},
    mixins: [ Utils ],
    data: () => ({
        periodo:  null,
        periodos: [
            { text: '1 dia', value: 1 },
            { text: '7 dias', value: 7 },
            { text: '15 dias', value: 15 },
            { text: '30 dias', value: 30 },
            { text: 'todo', value: -1 },
        ],
        loading: false,
        search: '',
        headers: [
            { text: 'Ocorrência', align: 'start', value: 'ocorrencia', sortable: false },
            { text: 'Evento', value: 'evento', sortable: false },
            { text: 'Administrador', value: 'adm', sortable: false },
            { text: 'Quadra', value: 'quadra', sortable: false },
            { text: 'Dia', value: 'dia', sortable: false },
            { text: 'Hora', value: 'hora', sortable: false },
            { text: 'Data', width: '115px', value: 'data', sortable: false },
            { text: 'Duração [min]', value: 'duracao', sortable: false },
            { text: 'Nome jogador', value: 'jogador', sortable: false },
            { text: 'Celular', value: 'celular', sortable: false },
            { text: 'Código sistema', value: 'codigo', sortable: false }
        ],
        events: [],
    }),
    mounted() {
        //this.fetchEvents()
    },
    methods: {
        fetchEvents: function () {
            this.loading = true
            this.$http({
                method: 'post',
                url: '/apiweb/historico',
                data: {
                    days: this.periodo,
                }
            }).then(response => {
                if (response.data.success == true) {
                    this.events = response.data.historico
                }
                this.loading = false
            })
        },
        download : function() {
            const data = Xlsx.utils.json_to_sheet(this.events)
            const wb = Xlsx.utils.book_new()
            Xlsx.utils.book_append_sheet(wb, data, 'historico')
            Xlsx.writeFile(wb,'Zumer_historico.xlsx')
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

</style>